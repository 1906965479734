import React, { useEffect } from "react"
import app from "gatsby-plugin-firebase-v9.0"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getFirestore, doc, setDoc } from "firebase/firestore"
import { navigate } from "gatsby"
import AuthLayout from "../../components/AuthLayout"
import { message, notification, Spin } from "antd"
import { getParameterByName } from "../../utils/helper"
import options from "../../../config/options"
import styled from "styled-components"

const config = require("../../../config/site")

const SpinWrapper = styled.div`
  margin: 60px auto 30px auto;
  text-align: center;
`

const LinePage = () => {
  const auth = getAuth(app)
  const db = getFirestore(app)
  let storageUser = null
  const isBrowser = typeof window !== "undefined"
  if (isBrowser) {
    storageUser = JSON.parse(localStorage.getItem("firebaseLocalStorage"))
  }

  const postLineNotify = async data => {
    try {
      return new Promise(function (resolve, reject) {
        const req = new XMLHttpRequest()
        req.open("POST", options.workerLineToken)
        req.setRequestHeader("Content-Type", "text/plain")
        req.onload = function () {
          if (req.status === 200) {
            resolve(JSON.parse(req.response))
          }
        }
        req.send(`${JSON.stringify(data)}`)
      })
    } catch (error) {
      console.log("postLineNotify error ", error)
      return false
    }
  }

  const handleConnectLine = async code => {
    try {
      const formData = {
        code: code,
        redirect_uri: `${config.siteUrl}auth/line`,
      }
      const response = await postLineNotify(formData)
      if (response && response?.status === 200) {
        const { access_token } = response
        if (storageUser?.uid && access_token && access_token?.length > 0) {
          const docRef = doc(db, "users", storageUser.uid)
          await setDoc(docRef, { lineToken: access_token }, { merge: true })
          message.success("Connect LINE Notify Successfully")
          navigate("/backend/profile")
        } else {
          message.info(
            "Unable to connect LINE Notify!, Please try again later..."
          )
          navigate("/backend/profile")
        }
      } else {
        message.info(
          "Unable to connect LINE Notify!, Please try again later..."
        )
        navigate("/backend/profile")
      }
    } catch (error) {
      console.error("Error connect line: ", error)
      notification["error"]({
        message: error.code || error.name || error.status,
        description: error.error_description || error.message,
        duration: 0,
      })
      navigate("/backend/profile")
    }
  }

  useEffect(() => {
    if (isBrowser) {
      onAuthStateChanged(auth, user => {
        if (user) {
          const code = getParameterByName("code")
          if (code && code.length > 0) {
            handleConnectLine(code)
          } else {
            navigate("/")
            return null
          }
        } else {
          navigate("/auth/signin")
          return null
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  return (
    <AuthLayout>
      <SpinWrapper>
        <Spin size="large" tip="Processing..." />
      </SpinWrapper>
    </AuthLayout>
  )
}

export default LinePage
